import React, { memo } from 'react';
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0 10px !important;

  ${up('sm')} {
    padding: 0 20px !important;
  }

  span {
    font-size: 22px;
    font-weight: 100;
    margin-top: 15px;
    color: #47494e;

    ${down('sm')} {
      margin-top: 10px;
      line-height: 28px;
    }

    @media only screen and (width: 768px) {
      font-size: 35px;
      margin-top: 35px;
    }

    @media only screen and (width: 1024px) {
      font-size: 45px;
      margin-top: 35px;
    }
  }

  p {
    font-size: 15px;
    color: #7f828b;
    line-height: 22px;
    margin-bottom: 4px;

    ${down('sm')} {
      margin-top: 10px;
    }

    @media only screen and (width: 768px) {
      font-size: 20px;
      margin-top: 10px;
      margin-bottom: 0;
      line-height: 31px;
    }

    @media only screen and (width: 1024px) {
      font-size: 30px;
      margin-top: 15px;
      line-height: 41px;
    }
  }
`;

const StyledDoppleLogo = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  img {
    width: 185px;
    height: 120px;
    display: flex;
    align-items: center;
    text-align: center;

    @media only screen and (width: 768px) {
      width: 250px;
      height: 140px;
    }

    @media only screen and (width: 1024px) {
      width: 300px;
      height: 160px;
    }
  }
`;

const Maintenance = () => (
  <Container>
    <StyledDoppleLogo>
      <img src="/static/images/logo.png" alt="logo" title="dopple-logo" srcSet="" />
    </StyledDoppleLogo>
    <p> We are busy updating our systems to provide you with a better experience. </p>
    <p> We apologize for the inconvenience and appreciate your patience.</p>
    <p> Please check back later. </p>
    <span> Thank you for using Dopple! </span>
  </Container>
);

export default memo(Maintenance);
