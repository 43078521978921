import { Context, useContext } from 'react';

export const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export const useCtx = <T>(ctx: Context<T>) => {
  const ctxVal = useContext(ctx);
  if (!ctxVal) throw new Error(`Cannot use ${ctx.displayName ?? 'context'} outside provider`);

  return ctxVal;
};
