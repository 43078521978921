import { configureScope, Scope } from '@sentry/browser';
import { NextComponentType, NextPageContext } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { ParsedUrlQuery } from 'querystring';
import { either, isEmpty, isNil, not, pathOr } from 'ramda';
import React, { createContext, createElement, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { useAsync } from 'react-async';
import Alert, { AlertType } from '../components/Alert';
import { User } from '../types';
import { identifyOnImpact, initPinterestTag, setClarityIdentity } from './analytics';
import client from './api-client';

function getUser(context?: NextPageContext) {
  return client<User>('api/users/me/', {}, context);
}

export const updateUser: (newInfo: User) => Promise<User> = newInfo =>
  client<User>('api/users/me/', { body: newInfo, method: 'PATCH' });

type UserContextType = {
  currentUser: User | null;
  isLoading: boolean;
  error?: Error;
  reload: () => void;
  setCurrentUser: (user: User) => void;
};

const emptyUser: UserContextType = {
  currentUser: null,
  isLoading: false,
  setCurrentUser: () => null,
  reload: () => {
    /* no op */
  },
};

const UserContext = createContext<UserContextType>(emptyUser);

type UserProviderProps = {
  children: ReactNode;
};

function UserProvider({ ...props }: UserProviderProps) {
  const promiseFn = useCallback(() => getUser(), []);
  const { data = null, isPending, error, reload, setData } = useAsync({ promiseFn });

  useEffect(() => {
    if (!data) return;

    configureScope((scope: Scope) => {
      scope.setUser({ email: data.email });
    });
    setClarityIdentity(data.email);
    identifyOnImpact(data.id, data.email_sha1);
    initPinterestTag(data.email_sha1);
  }, [data]);

  return (
    <UserContext.Provider
      value={{ currentUser: data, isLoading: isPending, error, reload, setCurrentUser: setData }}
      {...props}
    />
  );
}

function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
}

type UserOrRedirectProps = {
  children: ReactNode;
  returnUrlFn: ReturnUrlFn;
  redirectFn?: (user: User) => string;
};

function UserOrRedirect({ returnUrlFn, children, redirectFn }: UserOrRedirectProps) {
  const { isLoading, error, currentUser } = useUser();
  const router = useRouter();

  if (error) {
    const returnUrl = returnUrlFn(router.query);
    const redirectUrlQuery = queryString.stringify({ return: returnUrl });
    const redirectUrl = `/sign-in?${redirectUrlQuery}`;
    router.push(redirectUrl);
    return null;
  }

  if (isLoading) {
    return null;
  }

  if (redirectFn) {
    const redirectUrl = redirectFn(currentUser);
    if (redirectUrl) {
      router.push(redirectUrl);
      return null;
    }
  }

  return <>{children}</>;
}

type ReturnUrlFn = (query: ParsedUrlQuery | { [key: string]: string | string[] | undefined }) => string;

function withAuth<T>(
  WrappedComponent: NextComponentType<NextPageContext, T, T>,
  returnUrlFn: ReturnUrlFn,
  pageTitle?: string,
  pageDescription?: string,
  redirectFn?: (user: User) => string,
) {
  const WithAuthComponent = (props: any) => (
    <UserProvider>
      <Head>
        <link rel="shortcut icon" href="/images/favicon.ico" />
        <title>{pageTitle || 'Dopple'}</title>
        <meta name="description" content={pageDescription} />
      </Head>
      <UserOrRedirect returnUrlFn={returnUrlFn} redirectFn={redirectFn}>
        {createElement(WrappedComponent as any, props)}
      </UserOrRedirect>
    </UserProvider>
  );

  return WithAuthComponent;
}

type RequireMessages = {
  requireCreditCard?: string | ReactNode;
  requireShippingAddress?: string | ReactNode;
};

const defaultRequiredMessages: RequireMessages = {
  requireCreditCard: (
    <>
      You must complete your credit card information before continue, Please visit{' '}
      <Link href="/dashboard/account">your Account</Link> to enter in your payment details
    </>
  ),
  requireShippingAddress: (
    <>
      You must complete your shipping address before continue, Please visit{' '}
      <Link href="/dashboard/account">your Account</Link> to enter in your payment details
    </>
  ),
};

function withRequiredPaymentInfo(
  WrappedComponent: ReactNode,
  checkForAddress: boolean = false,
  requireMessages: RequireMessages = defaultRequiredMessages,
) {
  const userHas = (fieldPath, user) => {
    return not(either(isEmpty, isNil)(pathOr(null, fieldPath, user)));
  };

  return props => {
    const { currentUser, isLoading } = useUser();

    const userHasInfo = useMemo(
      () => ({
        creditCard: userHas(['stripe_payment_source_last4'], currentUser),
        shippingAddress: userHas(['address', 'address_1'], currentUser),
      }),
      [currentUser],
    );

    if (isLoading) {
      return <p>Loading user info...</p>;
    }

    if (!userHasInfo.creditCard) {
      return <Alert type={AlertType.ERROR}>{requireMessages.requireCreditCard}</Alert>;
    }

    if (checkForAddress && !userHasInfo.shippingAddress) {
      return <Alert type={AlertType.ERROR}> {requireMessages.requireShippingAddress}</Alert>;
    }
    return createElement(WrappedComponent as any, props);
  };
}

export { withAuth, useUser, UserProvider, getUser, withRequiredPaymentInfo };
