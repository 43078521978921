import { createGlobalStyle } from 'styled-components';
import kitsuneUdonWoff from '../../../public/static/fonts/KitsuneUdon.woff';
import kitsuneUdonWoff2 from '../../../public/static/fonts/KitsuneUdon.woff2';
import merriweatherRegularEot from '../../../public/static/fonts/merriweather-v20-latin-regular.eot';
import merriweatherRegularSvg from '../../../public/static/fonts/merriweather-v20-latin-regular.svg';
import merriweatherRegularTtf from '../../../public/static/fonts/merriweather-v20-latin-regular.ttf';
import merriweatherRegularWoff from '../../../public/static/fonts/merriweather-v20-latin-regular.woff';
import merriweatherRegularWoff2 from '../../../public/static/fonts/merriweather-v20-latin-regular.woff2';
import { Theme } from '../../theme';

const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
  /* merriweather-regular - latin */
  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    src: url(${merriweatherRegularEot}); /* IE9 Compat Modes */
    src: local('Merriweather Regular'), local('Merriweather-Regular'),
        url('${merriweatherRegularEot}?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url(${merriweatherRegularWoff2}) format('woff2'), /* Super Modern Browsers */
        url(${merriweatherRegularWoff}) format('woff'), /* Modern Browsers */
        url(${merriweatherRegularTtf}) format('truetype'), /* Safari, Android, iOS */
        url('${merriweatherRegularSvg}#Merriweather') format('svg'); /* Legacy iOS */
  }

  /* Kitsune Udon */
  @font-face {
    font-family: 'KitsuneUdon';
    src: url(${kitsuneUdonWoff2}) format('woff2'), /* Super Modern Browsers */
         url(${kitsuneUdonWoff}) format('woff'); /* Modern Browsers */

  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  *:focus {
    outline: none;
  }

 /* p, li {
    font-size: 14px;
    letter-spacing: 0.02em;
    line-height: 18px;
  }*/

  ul {
    list-style:none;
    padding-left: 0;
  }

  strong {
    font-weight: bolder;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
  }

  .italic {
    font-style: italic;
  }

  .opacity-50 {
    opacity: 0.5;
  }

 .opacity-40 {
    opacity: 0.4;
  }

  .width-full {
    width: 100%;
  }

  .width-95 {
    width: 95%;
  }

  .text-center {
    text-align: center;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .m-top-lg {
    margin-top: 30px;
  }

  .loader {
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .font-nunito {
    font-family: "Nunito Sans", Arial, sans-serif;
  }
`;

export default GlobalStyle;
