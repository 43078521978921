import { cookieDomain } from '../constants';
import jsCookie from 'js-cookie';

function setCookie<T>(key: string, value: T, shared: boolean = true) {
  const cookieOptions = cookieDomain && shared ? { domain: cookieDomain } : undefined;
  jsCookie.set(key, JSON.stringify(value), cookieOptions);
}

function getCookie<T>(key: string): T {
  const value = jsCookie.get(key);
  return value && JSON.parse(value);
}

function removeCookie(key: string, shared: boolean = true) {
  const cookieOptions = cookieDomain && shared ? { domain: cookieDomain } : undefined;
  jsCookie.remove(key, cookieOptions);
}

export { setCookie, getCookie, removeCookie };
