import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import storage from '../../utils/storage';
import { useRouter } from 'next/router';

const LOCAL_STORAGE_GIFT_CODE_STORE_KEY = 'THE_DOPPLE_GIFT_CODE_STORE';
const QUERY_PARAM_GIFT_DROP = 'gift_code';

type GiftCodeContextType = {
  giftCode: string | null;
  removeGiftCode: () => void;
};

const GiftCodeContext = createContext<GiftCodeContextType>({
  giftCode: null,
  removeGiftCode: () => null,
});

function GiftCodeProvider(props) {
  const [giftCode, _setGiftCode] = useState<string | null>(null);
  const { query } = useRouter();

  const setGiftCode = useCallback((value: string) => {
    try {
      storage.set(LOCAL_STORAGE_GIFT_CODE_STORE_KEY, { value });
    } catch (exc) {
    } finally {
      _setGiftCode(value);
    }
  }, []);

  const removeGiftCode = useCallback(() => {
    try {
      storage.remove(LOCAL_STORAGE_GIFT_CODE_STORE_KEY);
    } catch (exc) {
    } finally {
      _setGiftCode(null);
    }
  }, []);

  useEffect(() => {
    let defaultGiftCode: string;

    try {
      const { value } = storage.retrieve(LOCAL_STORAGE_GIFT_CODE_STORE_KEY);
      defaultGiftCode = value;
    } catch (exc) {
      defaultGiftCode = null;
    }

    const { [QUERY_PARAM_GIFT_DROP]: fromQuery } = query;

    const discoveredGiftCode = (fromQuery as string) || defaultGiftCode;

    if (discoveredGiftCode) {
      setGiftCode(discoveredGiftCode);
    }
  }, [query]);

  return <GiftCodeContext.Provider value={{ giftCode, removeGiftCode }} {...props} />;
}

function useGiftCode() {
  const context = useContext(GiftCodeContext);

  if (context === undefined) {
    throw new Error('useGiftCode must be used within a GiftCodeProvider');
  }

  return context;
}

export { GiftCodeProvider, useGiftCode };
