import { Address } from '../../types';

export type Product = {
  id: string;
  name: string;
  description: string;
  gender: string;
  images?: string[];
  price: number;
  brand: string;
  sold_out: boolean;
};

export type ProductEvent = Product & {
  product_items_by_event: ProductItem[];
};

export type ProductItem = Product & {
  image: string;
  size: string;
  color: string;
  price_msrp: number;
  type: string;
  quantity?: number;
  product?: { id: string };
  min_product_price?: number | string;
  max_product_price?: number | string;
  min_product_price_msrp?: number | string;
  max_product_price_msrp?: number | string;
  sku?: string;
  price_msrp_percentage?: number;
};

export type ShoppingCartProductItem = {
  price: number;
  item: ProductItem;
  quantity: number;
};

export type SaleEvent = {
  id: string;
  title: string;
  description: string;
  slug: string;
  is_active: boolean;
  featured_product_items?: ProductItem[];
  expires_at: string | null;
  ui_type: string;
  banner: string;
  show_price_msrp: boolean;
  show_women_size: boolean;
  show_brand_filter: boolean;
  banner_text: string;
  show_soldout_items_at_the_end: boolean;
  placeholder_notes_from_customer: string;
  show_notes_from_customer: boolean;
  required_notes_from_customer: boolean;
};

export type Order = {
  id: number;
  name: string;
  order_line_items: ShoppingCartProductItem[];
  price_subtotal: string;
  price_total: string;
  status: string;
  tax_total: string;
  shipping_total: string;
  shopify_applied_discount: AppliedDiscount;
};

export type AppliedDiscount = {
  title?: string;
  value?: string;
  amount?: string;
  value_type?: string;
  description?: string | null;
};

export type ShoppingCart = {
  status: string;
  id: string;
  order: Order;
};

export type ProductsApiResponse = {
  results: Product[];
  count: number;
  ui_type: string;
};

export type ProductItemsApiResponse = {
  results: ProductItem[];
  count: number;
  ui_type: string;
};

export type CheckoutFormValues = {
  shippingAddress: Address;
  billingName: string;
  billingAddress: Address;
  cardChanged: boolean;
  notesFromCustomer: string;
};

export type InventoryFilters = {
  gender?: string | string[];
  size?: string | string[];
  color?: string | string[];
  product_type?: string | string[];
  brand?: string | string[];
  ordering?: string | string[];
  styles?: string | string[];
  price_range?: string | string[];
};

export type InventoryProdFilters = InventoryFilters & {
  type?: string | string[];
  brand?: string | string[];
  created?: string | string[];
  page?: string | string[];
  price?: string | string[];
  price_range?: string | string[];
};

export type typeOption = {
  label: string;
  value: string;
};

export type SaleEventFilters = {
  product_types: typeOption[];
  brands: typeOption[];
};

export type ProductRyleeBlackFriday19 = {
  name: string;
  description: string;
  gender: string;
  price: number;
  image: string;
  brand: string;
  items: { [key: string]: ProductItem };
};

export type AddressFormValues = {
  user: boolean;
  usps: boolean;
};

export type VerifiedAddress = {
  address_1: string;
  address_2?: string;
  city: string;
  state: string;
  zip_code: string;
  verification_status: string;
};

export enum KlaviyoEvent {
  PRODUCT_ADDED_TO_CART = 'PRODUCT_ADDED_TO_CART',
  ORDER_PAID = 'ORDER_PAID',
  QUIZ_STEP = 'QUIZ_STEP',
  GIFT_CARD_PAID = 'GIFT_CARD_PAID',
  GIFT_DROP_PAID = 'GIFT_DROP_PAID',
  SALE_EVENT = 'SALE_EVENT',
}

export enum TrackingEventCategory {
  GIFT_DROP = 'GIFT_DROP',
  SALE_EVENT = 'SALE_EVENT',
  GIFT_CARD = 'GIFT_CARD',
  TRACK = 'track',
  TRACK_CUSTOM = 'trackCustom',
  EVENT = 'event',
  QUIZ = 'QUIZ',
  SINGLE_DROP = 'SINGLE_DROP',
}

export enum TrackingEventAction {
  PURCHASE = 'Purchase',
  GIFT_CARD_PURCHASE = 'GiftCardPurchase',
  GIFT_DROP_PURCHASE = 'GiftDropPurchase',
  SALE_EVENT_PURCHASE = 'SaleEventPurchase',
  CONVERSION = 'conversion',
}

export enum ProductFilterEnum {
  ORDERING = 'ordering',
  COLOR = 'color',
  SIZE = 'size',
  WOMEN = 'womenSize',
  PRICE = 'price',
  PRICE_RANGE = 'price_range',
  CREATE = 'create',
  GENDER = 'gender',
  BRAND = 'brand',
}

export enum UITypeEnum {
  REGULAR = 'REGULAR',
  SPECIAL = 'SPECIAL',
  DPDV1 = 'DPDV1',
}

type ImpactEventItem = {
  price: number;
  category: string;
  sku: string;
  quantity: number;
  name: string;
};

export type ImpactEvent = {
  orderId: string;
  customerId: string;
  customerEmail: string;
  customerStatus: 'New' | 'Returning';
  currencyCode: string;
  orderPromoCode: string;
  orderDiscount: number;
  items: ImpactEventItem[];
};

export enum PinterestEvent {
  PAGE_VIEW = 'pageview',
  SIGNUP = 'signup',
  CHECKOUT = 'checkout',
  ADD_TO_CART = 'addtocart',
}

type PinterestLineItem = {
  product_brand: string;
  product_id: string;
  product_price: number;
  product_variant: string;
  product_variant_id: string;
  product_name: string;
  product_quantity: number;
  product_category: string;
};

export type PinterestData = Partial<{
  currency: string;
  event_id: string;
  lead_type: string;
  order_id: string;
  order_quantity: number;
  promo_code: string;
  property: string;
  search_query: string;
  value: number;
  video_title: string;
  line_items: Partial<PinterestLineItem>[];
}>;
