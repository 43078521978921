import React, { createContext, useContext, useState } from 'react';
import useInitRetainedUrlParamsContext from './useInitRetainedUrlParamsContext';
import { UTMParams } from '../../types';

export type RetainedParamsContextState = UTMParams & {
  irclickid: string;
};

export type RetainedParamsContextType = {
  state: RetainedParamsContextState;
  setState: (state: RetainedParamsContextState) => void;
};

const initState: RetainedParamsContextState = {
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
  utm_term: '',
  utm_content: '',
  irclickid: '',
};

const RetainedParamsContext = createContext<RetainedParamsContextType>({
  state: initState,
  setState: () => null,
});

function RetainedUrlParamsProvider(props) {
  const [state, setState] = useState<RetainedParamsContextState>(initState);

  useInitRetainedUrlParamsContext({ state, setState });

  return <RetainedParamsContext.Provider value={{ state, setState }} {...props} />;
}

function useRetainedUrlParams(): RetainedParamsContextType {
  const context = useContext(RetainedParamsContext);

  if (context === undefined) {
    throw new Error('useRetainedUrlParams must be used within a UtmContextProvider');
  }

  return context;
}

export { RetainedUrlParamsProvider, useRetainedUrlParams };
