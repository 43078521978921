import tiktok from 'react-tiktok-helper';
import { ga4MeasurementId } from '../constants';
import { ImpactEvent, PinterestData, PinterestEvent, TrackingEventCategory } from '../features/sale-event/types';

function logGAEvent(category: string = '', action: string = '', value = undefined) {
  if (category && action) {
    logLegacyGtagEvent('event', action, { event_category: category, value, send_to: ga4MeasurementId });
  }
}

const logFBEvent = (category: string = 'track', action: string, extra: any = {}) => {
  if (window.fbq) {
    window.fbq(category, action, extra);
  }
};

const logLegacyGtagEvent = (category: string, action: string, extra: any = {}) => {
  if (window.gtag) {
    window.gtag(category, action, extra);
  }
};

function tiktokLogPageView(url: string) {
  window.ttq &&
    tiktok.event('ViewContent', {
      content_id: url,
      content_type: 'product_group',
    });
}

const pinterestTrack = (pinterestEvent: PinterestEvent, additionalData?: PinterestData) => {
  window.pintrk && window.isPintrkLoaded && window.pintrk('track', pinterestEvent, additionalData);
};

function logPageView() {
  console.log(`Logging pageview for ${window.location.pathname}`);
  tiktokLogPageView(window.location.pathname);
  logFBEvent('track', 'PageView');
  pinterestTrack(PinterestEvent.PAGE_VIEW);
}

const logCustomPageView = (url: string) => {
  try {
    url = new URL(url, window.location.origin).href;
  } catch {}
  logLegacyGtagEvent('event', 'page_view', { page_location: url, send_to: ga4MeasurementId });

  logFBEvent('trackCustom', 'VirtualPageView', { url });
  tiktokLogPageView(url);
};

const tiktokLogPurchase = (category: TrackingEventCategory, value) => {
  window.ttq &&
    tiktok.event('Purchase', {
      content_type: category,
      value,
      currency: 'USD',
    });
};

const identifyOnImpact = (userId: number | string, emailSha1: string) => {
  window.ire && window.ire('identify', { userId, customerEmail: emailSha1 });
};

const logImpactEvent = (actionId: number, data: ImpactEvent) => {
  window.ire &&
    window.ire('trackConversion', actionId, data, {
      verifySiteDefinitionMatch: true,
    });
};
const initPinterestTag = (userEmailSha1: string) => {
  if (window.pintrk && !window.isPintrkLoaded) {
    window.pintrk('load', process.env.PINTEREST_TAG_ID, { em: userEmailSha1 });
    window.pintrk('page');
    window.isPintrkLoaded = true;
  }
};

const setClarityIdentity = (email: string) => {
  const w: any = window;
  w.clarity =
    w.clarity ||
    function(...args) {
      (w.clarity.q = w.clarity.q || []).push(args);
    };

  w.clarity('identify', email);
};

export {
  logPageView,
  logGAEvent,
  logFBEvent,
  logLegacyGtagEvent,
  logCustomPageView,
  tiktokLogPurchase,
  identifyOnImpact,
  logImpactEvent,
  pinterestTrack,
  initPinterestTag,
  setClarityIdentity,
};
