import 'isomorphic-unfetch';
import jsCookie from 'js-cookie';
import { NextPageContext } from 'next';
import { tokenCookieName } from '../constants';
import { User } from '../types';

const LS_KEY = 'THE_DOPPLE_USER';

function tokenFromLocalStorage(): string | null {
  if (window !== undefined && window.localStorage) {
    // This is defined in www/src/utils/storage/index.js
    const value = window.localStorage.getItem(LS_KEY);
    if (value) {
      try {
        const user = JSON.parse(value);
        return user.auth_token || null;
      } catch (e) {
        return null;
      }
    }
  }

  return null;
}

function tokenFromCookies(context: any): string | null {
  try {
    const token = jsCookie.get(tokenCookieName);
    return token || null;
  } catch (e) {
    return null;
  }
}

function userToLocalStorage(user: User) {
  if (window !== undefined && window.localStorage) {
    window.localStorage.setItem(LS_KEY, JSON.stringify(user));
  }
}

function getToken(context: any): string | null {
  const localStorageToken = tokenFromLocalStorage();
  if (localStorageToken !== undefined && localStorageToken !== null && localStorageToken !== '') {
    return localStorageToken;
  }

  return tokenFromCookies(context);
}

const httpStatusCodes: Record<string, string> = {
  '200': 'OK',
  '201': 'Created',
  '202': 'Accepted',
  '203': 'Non-Authoritative Information',
  '204': 'No Content',
  '205': 'Reset Content',
  '206': 'Partial Content',
  '300': 'Multiple Choices',
  '301': 'Moved Permanently',
  '302': 'Found',
  '303': 'See Other',
  '304': 'Not Modified',
  '305': 'Use Proxy',
  '306': 'Unused',
  '307': 'Temporary Redirect',
  '308': 'Permanent Redirect',
  '400': 'Bad Request',
  '401': 'Unauthorized',
  '402': 'Payment Required',
  '403': 'Forbidden',
  '404': 'Not Found',
  '405': 'Method Not Allowed',
  '406': 'Not Acceptable',
  '407': 'Proxy Authentication Required',
  '408': 'Request Timeout',
  '409': 'Conflict',
  '410': 'Gone',
  '411': 'Length Required',
  '412': 'Precondition Required',
  '413': 'Request Entry Too Large',
  '414': 'Request-URI Too Long',
  '415': 'Unsupported Media Type',
  '416': 'Requested Range Not Satisfiable',
  '417': 'Expectation Failed',
  '418': "I'm a teapot",
  '429': 'Too Many Requests',
  '500': 'Internal Server Error',
  '501': 'Not Implemented',
  '502': 'Bad Gateway',
  '503': 'Service Unavailable',
  '504': 'Gateway Timeout',
  '505': 'HTTP Version Not Supported',
};
function onFetchDone(response: Response) {
  //handle no content response (204)
  if (response.status === 204) {
    return Promise.resolve();
  }
  if (response.status >= 200 && response.status < 300) {
    return response.json();
  } else {
    // http2 doesn't define a statusText (https://www.rfc-editor.org/rfc/rfc7540#section-8.1.2.4)
    const error = new Error(
      response.statusText || httpStatusCodes[String(response.status)] || `Network Error: ${response.status}`,
    );
    // @ts-ignore
    error.response = response;
    throw error;
  }
}

function client<T>(endpoint: string, config: any = {}, context?: NextPageContext): Promise<T> {
  const token = getToken(context || {});
  const headers: Record<string, string> = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (token) {
    headers.Authorization = `Token ${token}`;
  }

  const { body, ...restConfig } = config;
  const bodyConfig = body ? { body: JSON.stringify(body) } : {};

  const fetchConfig = {
    method: config.method || (body ? 'POST' : 'GET'),
    ...restConfig,
    ...bodyConfig,
    headers: {
      ...headers,
      ...restConfig.headers,
    },
    credentials: 'include',
  };

  return fetch(`${process.env.API_URL}/${endpoint}`, fetchConfig)
    .then(onFetchDone)
    .catch(error => {
      console.error('API Error:', error);
      throw error;
    });
}

export { getToken, userToLocalStorage };

export default client;
