import React, { useEffect } from 'react';
import { useUser } from '../../utils/auth-client';
import { canUseDOM } from '../../utils';

function Friendbuy() {
  const { currentUser } = useUser();

  if (!canUseDOM) {
    return <></>;
  }

  if (!window.friendbuy) {
    (function(f, r, n, d, b, y) {
      (b = f.createElement(r)), (y = f.getElementsByTagName(r)[0]);
      b.async = 1;
      b.src = n;
      y.parentNode.insertBefore(b, y);
    })(document, 'script', '//djnf6e5yyirys.cloudfront.net/js/friendbuy.min.js');
  }

  useEffect(() => {
    window.friendbuy = window.friendbuy || [];
    window.friendbuy.push(['site', 'site-f6f5f63c-thedopple.com']);
  }, []);

  useEffect(() => {
    if (currentUser) {
      const { email, first_name, last_name } = currentUser;
      window.friendbuy.push([
        'track',
        'customer',
        {
          id: email,
          email,
          first_name,
          last_name,
        },
      ]);
    }
  }, [currentUser]);

  return <></>;
}

export default Friendbuy;
