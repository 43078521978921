import styled from 'styled-components';
import { down } from 'styled-breakpoints';

export enum AlertType {
  SUCCESS,
  ERROR,
  INFO,
  WARNING,
}

type Props = {
  type: AlertType;
};

const Alert = styled.div<Props>`
  width: 100%;
  min-height: 3rem;
  border-radius: 4px;
  padding: 1rem;
  text-align: center;
  margin: 20px;
  line-height: 20px;
  ${down('sm')} {
    margin: 20px 0;
  }
  ${({ type, theme }) =>
    type === AlertType.ERROR && 'background-color: #FFD2D2;' + `color: ${theme.colors.primaryDanger};`}
  ${({ type }) => type === AlertType.SUCCESS && 'background-color: #DFF2BF;' + 'color: #4F8A10;'}
  ${({ type }) => type === AlertType.INFO && 'background-color: #eef6fc; color: #1d72aa;'}
  ${({ type }) => type === AlertType.WARNING && 'background-color: #FFEDD1; color:#ff6621;'}
`;

export default Alert;
