import { useEffect } from 'react';
import { RetainedParamsContextState, RetainedParamsContextType } from './index';
import { useRouter } from 'next/router';
import { keys, pick } from 'ramda';
import { getCookie, setCookie } from '../../utils/cookie';

const RETAINED_PARAMS_COOKIE_NAME = 'DOPPLE_URL_OBJECT';

const RETAINED_URL_PARAMS_NAMES = ['utm_source', 'utm_content', 'utm_campaign', 'utm_medium', 'utm_term', 'irclickid'];

function useInitRetainedUrlParamsContext({ setState }: RetainedParamsContextType) {
  const { query } = useRouter();

  useEffect(() => {
    const storedParams = getCookie<RetainedParamsContextState>(RETAINED_PARAMS_COOKIE_NAME);
    const params = pick(RETAINED_URL_PARAMS_NAMES, query);
    if (keys(params).length > 0) {
      const newParams = {
        ...storedParams,
        ...params,
      };
      setState(newParams as RetainedParamsContextState);
      setCookie<RetainedParamsContextState>(RETAINED_PARAMS_COOKIE_NAME, newParams as RetainedParamsContextState);
    } else {
      storedParams && setState(storedParams as RetainedParamsContextState);
    }
  }, [query]);
}

export default useInitRetainedUrlParamsContext;
