import * as Sentry from '@sentry/browser';
import App from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import Script from 'next/script';
import { complement, either, isEmpty, isNil } from 'ramda';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-medium-image-zoom/dist/styles.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import tiktok from 'react-tiktok-helper';
import { ThemeProvider } from 'styled-components';
import 'url-search-params-polyfill';
import '../public/static/css/webflow.css';
import Friendbuy from '../src/components/Friendbuy';
import GlobalStyle from '../src/components/GlobalStyle';
import Maintenance from '../src/components/Maintenance';
import { GiftCodeProvider } from '../src/contexts/GiftCode';
import { PromoCodeProvider } from '../src/contexts/PromoCode';
import { RetainedUrlParamsProvider } from '../src/contexts/RetainedUrlParamsContext';
import { ApplicationArgs } from '../src/features/QueryStringArgsIntegration';
import theme from '../src/theme';
import { logPageView } from '../src/utils/analytics';

const isStringUndefined = (str: string) => str === 'undefined';
const isNotNilNorEmpty = complement(either(either(isNil, isEmpty), isStringUndefined));

const SENTRY_DSN = `${process.env.SENTRY_DSN}`;
const TIKTOK_APP_ID = `${process.env.TIKTOK_APP_ID}`;
const WAFFLE_JS_URL = `${process.env.API_URL}/wafflejs`;

if (isNotNilNorEmpty(SENTRY_DSN)) {
  Sentry.init({
    dsn: SENTRY_DSN,
  });
}

type DoppleAppState = {
  stripe: any;
};

type DoppleAppProps = {};

const initTiktokPixel = () => {
  tiktok.config(`${TIKTOK_APP_ID}`);
};

export default class DoppleApp extends App<DoppleAppProps, DoppleAppState> {
  componentDidMount() {
    initTiktokPixel();

    logPageView();

    if (Router && Router.router) {
      Router.router.events.on('routeChangeComplete', logPageView);
    }
  }

  componentDidCatch(error: any, errorInfo: any) {
    if (isNotNilNorEmpty(SENTRY_DSN)) {
      Sentry.withScope((scope: any) => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });

        Sentry.captureException(error);
      });
    }
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <>
        <Head>
          <title>Dopple</title>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no, shrink-to-fit=no" />
          <meta name="theme-color" content="#000000" />
          <meta name="color-scheme" content="only" />
          <Script src={WAFFLE_JS_URL} />
        </Head>
        {typeof window !== 'undefined' && window?.waffle?.switch_is_active('maintenance_mode') ? (
          <Maintenance />
        ) : (
          <>
            <ApplicationArgs />
            <ThemeProvider theme={theme}>
              <>
                <GlobalStyle />
                <GiftCodeProvider>
                  <PromoCodeProvider>
                    <RetainedUrlParamsProvider>
                      <Component {...pageProps} />
                    </RetainedUrlParamsProvider>
                  </PromoCodeProvider>
                </GiftCodeProvider>
              </>
            </ThemeProvider>
            <Friendbuy />
          </>
        )}
      </>
    );
  }
}
