import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { both, complement, isEmpty, isNil, mergeWith } from 'ramda';

import client from '../../utils/api-client';
import { useRouter } from 'next/router';
import { getCookie, removeCookie, setCookie } from '../../utils/cookie';

const COOKIE_PROMO_CODE_STORE_KEY = 'THE_DOPPLE_PROMO_CODE_STORE';
const QUERY_PARAM_QUIZ_PROMO = 'd_coupon';
const QUERY_PARAM_GIFT_PROMO = 'gift_promo';

enum PROMO_CODE_KEY {
  QUIZ = 'quiz',
  GIFT = 'gift_drop',
}

type PromoCodeType = {
  [key in PROMO_CODE_KEY]: string | null;
};

type PromoCodeContextType = {
  promoCodes: PromoCodeType;
  removePromoCodes: () => null;
};

const INITIAL_VALUE = {
  [PROMO_CODE_KEY.GIFT]: null,
  [PROMO_CODE_KEY.QUIZ]: null,
};

const PromoCodeContext = createContext<PromoCodeContextType>({
  promoCodes: INITIAL_VALUE,
  removePromoCodes: () => null,
});

function PromoCodeProvider(props) {
  const [promoCodes, _setPromoCodes] = useState<PromoCodeType>(INITIAL_VALUE);
  const { query } = useRouter();

  const setPromoCodes = useCallback((value: PromoCodeType) => {
    try {
      setCookie(COOKIE_PROMO_CODE_STORE_KEY, value);
    } catch (exc) {
    } finally {
      _setPromoCodes(value);
    }
  }, []);

  const removePromoCodes = useCallback(() => {
    try {
      removeCookie(COOKIE_PROMO_CODE_STORE_KEY);
    } catch (exc) {
    } finally {
      _setPromoCodes(INITIAL_VALUE);
    }
  }, []);

  useEffect(() => {
    const fun = async () => {
      /*
       * There are three sources for promo codes:
       *   3. globals - these are configured and loaded _once_ from the API
       *   2. local storage - matches state changes, _removed_ upon successful submission
       *   1. query string - these are loaded _once_ from the query string
       *
       * The order implies the override sequence. For example, a quiz promo
       *   retrieved from the API (global) would be overridden by a quiz promo
       *   retrieved from local storage, which in turn would be overridden by
       *   a quiz promo retrieved from the query string
       */
      let persisted: PromoCodeType;

      try {
        persisted = getCookie(COOKIE_PROMO_CODE_STORE_KEY);
      } catch (exc) {
        persisted = INITIAL_VALUE;
      }

      let globals: PromoCodeType;

      try {
        globals = await client('api/coupons/defaults/');
      } catch (exception) {
        globals = INITIAL_VALUE;
      }

      const { [QUERY_PARAM_QUIZ_PROMO]: quiz_promo, [QUERY_PARAM_GIFT_PROMO]: gift_promo } = query;

      const queryParams = {
        [PROMO_CODE_KEY.QUIZ]: quiz_promo,
        [PROMO_CODE_KEY.GIFT]: gift_promo,
      };

      const comp = (l, r) => {
        // Initial is on the left - we prefer values from this object,
        //  Default is on the right - we accept this if initial is invalid
        const valid = both(complement(isEmpty), complement(isNil));

        if (valid(l)) {
          return l;
        }

        if (valid(r)) {
          return r;
        }

        return null;
      };

      const defaults: PromoCodeType = mergeWith(comp, persisted, globals);

      const promos: PromoCodeType = mergeWith(comp, queryParams, defaults);

      setPromoCodes(promos);
    };

    fun();
  }, [query]);

  return <PromoCodeContext.Provider value={{ promoCodes, removePromoCodes }} {...props} />;
}

function usePromoCode() {
  const context = useContext(PromoCodeContext);

  if (context === undefined) {
    throw new Error('usePromoCode must be used within a PromoCodeProvider');
  }

  return context;
}

export { PromoCodeProvider, usePromoCode };
