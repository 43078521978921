import React, { useEffect } from 'react';
import { useRouter, NextRouter } from 'next/router';
import { forEachObjIndexed } from 'ramda';
import storage from '../../utils/storage';

type ApplicationArgsType = {
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmTerm?: string;
  utmContent?: string;
  // TODO: add coupon args
};

type SetApplicationArgsType = (args: ApplicationArgsType) => ApplicationArgsType;

const key = 'THE_DOPPLE_APP_ARGS';

const queryToArgsMapping = {
  utm_source: 'utmSource',
  utm_medium: 'utmMedium',
  utm_campaign: 'utmCampaign',
  utm_term: 'utmTerm',
  utm_content: 'utmContent',
  // TODO: add coupon args
};

let applicationArgs: ApplicationArgsType = {};

function getArgsFromLocalStorage(): ApplicationArgsType {
  return storage.retrieve(key) || {};
}

function setArgsToLocalStorage(args: ApplicationArgsType) {
  storage.set(key, args);
}

export function queryFromUrl(url: string, blacklist?: string[]) {
  const [, ...queryStrings] = url.split('?');
  const queryString = queryStrings.join('?');
  const query = {};
  const searchParams = new URLSearchParams(queryString);
  const searchParamsEntries = searchParams?.entries();

  if (searchParamsEntries !== undefined) {
    if (!searchParamsEntries[Symbol.iterator]) {
      searchParamsEntries[Symbol.iterator] = () => searchParamsEntries;
    }

    for (const [key, value] of searchParamsEntries) {
      if (!blacklist?.includes(key)) {
        query[key] = value;
      }
    }
  }
  return query;
}

function extractQueryFromRouter(router: NextRouter) {
  return {
    ...router.query,
    ...queryFromUrl(router.asPath),
  };
}

function getArgsFromQueryString(router: NextRouter): ApplicationArgsType {
  const result: ApplicationArgsType = {};
  forEachObjIndexed((value, key) => {
    if (key in queryToArgsMapping) {
      result[queryToArgsMapping[key]] = value;
    }
  }, extractQueryFromRouter(router));
  return result;
}

function setApplicationArgs(args: ApplicationArgsType): ApplicationArgsType {
  const localStorageArgs = getArgsFromLocalStorage();
  applicationArgs = { ...localStorageArgs, ...applicationArgs, ...args };
  setArgsToLocalStorage(applicationArgs);
  return applicationArgs;
}

function ApplicationArgs() {
  const router = useRouter();

  useEffect(() => {
    const queryStringArgs = getArgsFromQueryString(router);
    setApplicationArgs(queryStringArgs);
  }, []);

  return <></>;
}

function useApplicationArgs(): [ApplicationArgsType, SetApplicationArgsType] {
  return [applicationArgs, setApplicationArgs];
}

export { ApplicationArgs, useApplicationArgs };
